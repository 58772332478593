import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Table } from "react-bootstrap";
import * as XLSX from "xlsx"; // Import xlsx library
import {
  fetchSchoolAssessmentSummary,
  getAssessmentTypes,
} from "../services/APIClient";

const SchoolAssessmentReport = () => {
  const [filters, setFilters] = useState({
    dateFrom: "",
    excludedCountryId: "",
    assessmentId: "",
    gradeLevel: "",
  });

  const countries = [
    { id: "07d45b79-24b1-435f-b82a-93861b23a86e", name: "Republic of Ireland" },
    {
      id: "1cb16a5c-7c0a-4e7a-bcbc-b409024dd6cf",
      name: "United Arab Emirates",
    },
    { id: "4b055248-1702-4fac-a63f-927245ec108e", name: "New Zealand" },
    { id: "4cceaa3b-dcc4-43da-b2f2-61fa98adc744", name: "Canada" },
    { id: "5794e501-b06a-4963-b8b3-87665cc073ae", name: "Wales" },
    { id: "5a2136da-62fa-4193-803e-33fd9be5dd87", name: "Scotland" },
    { id: "622e7844-a15e-4b91-9f64-12bf71ed7cff", name: "Northern Ireland" },
    { id: "74bb2897-dbbe-41e3-b547-caee866545cf", name: "England" },
    { id: "ab40949f-001e-48b9-848e-126decad8471", name: "USA" },
    { id: "f2d8569e-47ca-4e88-8736-797a7acd37f3", name: "Malaysia" },
  ];

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [assessments, setAssessments] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleAssessmentChange = (e) => {
    const selectedAssessmentId = e.target.value;
    const selectedAssessment = assessments.find(
      (assessment) => assessment.id === selectedAssessmentId
    );

    setFilters({
      ...filters,
      assessmentId: selectedAssessmentId,
      gradeLevel: selectedAssessment.grade_level1
        ? "grade_level1"
        : "grade_level2",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const result = await fetchSchoolAssessmentSummary(filters);
      setData(result); // Set the fetched data into state
    } catch (err) {
      setError("Failed to fetch data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleExportToExcel = () => {
    // Prepare data for export
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      "School Assessment Report"
    );

    // Trigger download
    XLSX.writeFile(workbook, "SchoolAssessmentReport.xlsx");
  };

  useEffect(() => {
    getAssessmentTypes()
      .then((response) => {
        const filteredAssessments = response.filter(
          (assessment) => assessment.task !== true
        );
        setAssessments(filteredAssessments);
      })
      .catch(() => setError("Failed to fetch assessments."));
  }, []);

  return (
    <Container fluid className="p-4">
      <Row>
        <Col>
          <h3>School Assessment Report</h3>
        </Col>
      </Row>

      {/* Filter Form */}
      <Row className="mb-4">
        <Col md={6}>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="dateFrom" className="mb-3">
              <Form.Label>Date From</Form.Label>
              <Form.Control
                type="date"
                name="dateFrom"
                value={filters.dateFrom}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="excludedCountryId" className="mb-3">
              <Form.Label>Excluded Country</Form.Label>
              <Form.Select
                name="excludedCountryId"
                value={filters.excludedCountryId}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Country</option>
                {countries.map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="assessmentId" className="mb-3">
              <Form.Label>Assessment</Form.Label>
              <Form.Select
                name="assessmentId"
                value={filters.assessmentId}
                onChange={handleAssessmentChange}
                required
              >
                <option value="">Select Assessment</option>
                {assessments.map((assessment) => (
                  <option key={assessment.id} value={assessment.id}>
                    {assessment.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <button className="blue-button" type="submit" disabled={isLoading}>
              {isLoading ? "Loading..." : "Fetch Data"}
            </button>
          </Form>
        </Col>
      </Row>

      {/* Error Message */}
      {error && (
        <Row className="mb-4">
          <Col>
            <div className="alert alert-danger">{error}</div>
          </Col>
        </Row>
      )}

      {/* Data Table */}
      <Row>
        <Col>
          <Table striped bordered hover style={{ backgroundColor: "white" }}>
            <thead>
              <tr>
                <th>School Name</th>
                <th>Roll Number</th>
                <th>Area</th>
                <th>Total Grade Level Students</th>
                <th>Started Assessments</th>
                <th>Completed Assessments</th>
                <th>Alpaca Lead</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.schoolname}</td>
                    <td>{item.rollnumber}</td>
                    <td>{item.area}</td>
                    <td>{item["Total Grade Level 1 Students"]}</td>
                    <td>{item["Started Assessments"]}</td>
                    <td>{item["Completed Assessments"]}</td>
                    <td>{item["Alpaca Lead"]}</td>
                    <td>{item.email}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">
                    {isLoading ? "Loading data..." : "No data available"}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {data.length > 0 && (
            <button onClick={handleExportToExcel} className="blue-button">
              Export
            </button>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default SchoolAssessmentReport;
